import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`


  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title="Ice Cream Cakes and Pies"
        description="Boice Bros. Ice Cream offers stock and custom order cakes and pies. To place an order, call us during business hours at (845) 340-2018."
      />
      <div className="page icecream"><div className="container"><div className="inner">

        <h2 className="color_yellow">Ice Cream Cakes and Pies</h2>

        <p>Boice Bros. Ice Cream offers stock and custom order cakes and pies.  To place an order, call us during business hours at (845) 340-2018.</p>

        <p>* Food Allergy Warning – product may come into contact with nuts.</p>

        <h3 className="color_pink">ICE CREAM CAKE OPTIONS FOR ORDER</h3>

        <table><tbody>

          <tr><td>Cake Size</td><td>Serves</td></tr>
          <tr><td>6"</td><td>6-8</td></tr>
          <tr><td>8"</td><td>10-15</td></tr>
          <tr><td>10"</td><td>20-25</td></tr>
          <tr><td>12"</td><td>30-35</td></tr>
        </tbody></table>

        <p>Stock cakes are made with vanilla and chocolate ice cream with chocolate cookie crunch and fudge.</p>

        <p><strong>Specialty Cake Options</strong></p>

        <p>Additional fillings for specialty cakes offered at additional charge.
Fillings available: strawberry, marshmallow, Butterfinger, pineapple,
brownies, cookie dough, wet walnuts, peanut butter, Nestle Crunch, 
Kit Kat, black cherry, peanut butter cups, rainbow sprinkles, 
Oreos, Reeses Pieces, caramel, chocolate sprinkles, chocolate chips, 
peanuts, gummy bears, toasted coconut. 
All vanilla, all chocolate and all twist ice cream cakes are available 
at additional charge.</p>

<p>Specialty artwork for all cakes are available at additional charge.</p>

<p>For specialty orders, we will need the following information:<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  Message on cake:<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  Icing color:<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  Bill to (name):<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  Contact information:<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  Pick up date & time:</p>

<h3 className="color_purple">ICE CREAM PIE (COWPIE) OPTIONS FOR ORDER</h3>

<p>Flavors: vanilla, chocolate, twist<br />
Crust: graham, chocolate, oreo</p>

<p>Additional toppings available at additional charge.
Toppings offered: fudge, peanut butter, peanut butter cups, 
Reese’s Pieces, peanuts, caramel, marshmallow, chocolate syrup,
strawberry, syrup, wet walnuts, cookie dough, Oreos, cookie crunches, M&M’s, Heath, rainbow sprinkles, chocolate sprinkles, Nestle Crunch, 
Butterfinger, gummy bears, chocolate chips, Snickers, Kit Kats, 
pineapple, strawberries, blueberries, black cherries, black raspberries and 
Maraschino Cherries.<br />
Whipped cream is also available</p>

<p>For specialty orders, we will need the following information:<br />
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  Bill to (name):<br />
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  Contact information:<br />
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  Pick up date & time:</p>







      </div></div></div>
    </Layout>
  )



}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`
